import * as _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {TopicForm} from "./TopicForm";
import {TopicActions} from "./TopicActions";
import {Subtopic} from "./Subtopic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {MuiAddSubtopicButton} from "./styles/topic";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";

export const Topic = ({
                        topic,
                        index,
                        handleTopicChange,
                        handleDelete,
                        hierarchyMode,
                        answerVariable,
                        rootClassificationVariable,
                        collection,
                        weightVariable,
                        dataRegion
                      }) => {

  const [topicExpanded, setTopicExpanded] = useState(false);
  const {t} = useTranslation();

  const handleAddSubTopic = () => {
    let localTopic = _.clone(topic);
    localTopic.subtopics = localTopic.subtopics || [];
    localTopic.subtopics.push({
      topic: '',
      description: '',
      isParent: false,
      representativity: 0
    });
    handleTopicChange(localTopic, index);
  }

  const handleDeleteSubtopic = (sIndex) => {
    let localTopic = _.clone(topic);
    localTopic.subtopics.splice(sIndex, 1);
    handleTopicChange(localTopic, index);
  };

  const handleUpdateSubtopic = (st, sIndex) => {
    let localTopic = _.clone(topic);
    localTopic.subtopics[sIndex] = st;
    handleTopicChange(localTopic, index);
  };

  return (
    <Accordion expanded={topic.subtopics?.length > 0 && topicExpanded}
               onChange={() => setTopicExpanded(prev => !prev)}
               sx={{
                 '&.MuiPaper-root': {
                   margin: '0px',
                   borderBottom: '1px solid #E0E0E0',
                   backgroundColor: '#F5F5F5'
                 }
               }}
    >
      <AccordionSummary
        expandIcon={(topic.subtopics?.length > 0) ? (<ExpandMoreIcon/>) :
          <div style={{width: '24px'}}/>}
        aria-controls={`panel-topic-${index}-content`}
        id={`panel-topic-${index}-header`}
        sx={{
          padding: '16px 12px 16px 12px',
          height: hierarchyMode==='None'?'83px':'62px',
          flexDirection: 'row-reverse',
          backgroundColor: '#F5F5F5 !important',
        }}>
        <Grid
          container
          direction='row'
          sx={{
            // minHeight: hierarchyMode==='None'?'83px':'62px',
            height:  hierarchyMode==='None'?'83px':'62px',
            backgroundColor: "#F5F5F5",
            width: '100%',
            ml: '4px'
          }}
          alignContent='center'
        >
          {hierarchyMode === 'None' ? (
            <Grid item xs={true} id={'topic-form-container'}
                  alignContent='center'>
              <TopicForm topic={topic} index={index}
                         handleTopicChange={handleTopicChange}
                         isRootTopic={true}
                         rootIsConditioned={false}/>
            </Grid>
          ) : (
            <Grid item xs={'auto'} alignContent='center'>
              <Typography sx={{
                fontFamily: 'Raleway',
                fontSize: '21px',
                fontWeight: 500,
                lineHeight: '25px',
                letterSpacing: '0.0015em',
                color: '#616161'
              }}>
                {topic.topic}
              </Typography>
            </Grid>
          )}
          <Grid container xs={hierarchyMode !== 'None'} item
                id={'topic-actions-container'}
                sx={{width: '135px'}} alignContent='center'>
            <TopicActions topic={topic}
                          index={index}
                          isRootTopic={true}
                          hierarchyMode={hierarchyMode}
                          rootClassificationVariable={rootClassificationVariable}
                          handleDelete={handleDelete}
                          handleTopicChange={handleTopicChange}
                          answerVariable={answerVariable}
                          collection={collection}
                          dataRegion={dataRegion}
                          weightVariable={weightVariable}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{backgroundColor: "#F0F0F0", padding: '0px',borderRadius:'12px',border:'1px solid #F0F0F0',mb:'5px'}}>
        <Grid container item direction='column' xs={12} sx={{pt: '16px', borderRadius:'12px'}}>
          <>
            {(hierarchyMode !== 'None') && (
              <Grid container item direction='row' sx={{pl: '12px'}}
                    columnGap={'8px'}>
                <Grid item sx={{width: '36%'}}>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14px',
                    letterSpacing: '0.004em'
                  }}>
                    {t('name')}:
                  </Typography>
                </Grid>
                <Grid item xs={true}>
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14px',
                    letterSpacing: '0.004em'
                  }}>
                    {t('description')}:
                  </Typography>
                </Grid>
              </Grid>
            )}
            {topic.subtopics?.map((subtopic, stIndex) => {
              return (
                <Subtopic
                  key={`topic-${index}-${stIndex}`}
                  topic={subtopic}
                  index={stIndex}
                  handleTopicChange={handleUpdateSubtopic}
                  handleDelete={handleDeleteSubtopic}
                  answerVariable={answerVariable}
                  collection={collection}
                  dataRegion={dataRegion}
                />
              );
            })}
            {(topic.subtopics?.length > 0) &&
              <Grid item xs={12} sx={{padding: '12px 16px'}}>
                <MuiAddSubtopicButton
                  sx={{
                    width: '100%',
                  }}
                  onClick={handleAddSubTopic}
                >
                  {hierarchyMode !== 'None' ? t('add_topic') : t('add_subtopic')}
                </MuiAddSubtopicButton>
              </Grid>}
          </>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
