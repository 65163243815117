import * as _ from "lodash";
import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import * as React from "react";
import {useRef} from "react";
import {Topics} from "./Topics";
import {TopicExtractionControls} from "./TopicExtractionControls";
import MoreVert from "@mui/icons-material/MoreVert";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {Download} from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import {VerticalClassificationControls} from "./VerticalClassificationControls";
import axiosInstance from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";

export const TopicManagement = ({
                                  question,
                                  variables,
                                  parentCandidates,
                                  dataRegion,
                                  collection,
                                  weight,
                                  handleQuestionChange
                                }) => {

  const [segmentationCandidates, setSegmentationCandidates] = React.useState([]);
  const [openImportExportModal, setOpenImportExportModal] = React.useState(null);

  const fileInputRef = useRef(null);
  const {t} = useTranslation();

  const initialize = () => {
    setSegmentationCandidates(variables.filter(d => d.category === 'categorical'));
  }

  React.useEffect(() => {
    initialize();
  }, [question]);

  const handleImportTopics =  (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const localQuestion = _.clone(question);
    localQuestion.classified = false;
    let topicsLocal = [...question.topics];
    reader.onload = async (e) => {
      try {
        const importedData = JSON.parse(e.target.result);
        const newTopics=await updateTopicIcons(importedData);
        if (!topicsLocal||topicsLocal?.length === 0) {
          localQuestion.topics = newTopics;
          handleQuestionChange(localQuestion);
        } else {
          const localTopics = [...topicsLocal];
          newTopics.forEach(secondItem => {
            let firstItem = localTopics.find(item => item.topic === secondItem.topic);

            // If the topic does not exist in the first list, add it
            if (!firstItem) {
              localTopics.push(secondItem);
            } else {
              // If the topic exists but the subtopics are empty, add the subtopics
              if (firstItem.subtopics?.length === 0 && secondItem.subtopics?.length > 0) {
                firstItem.subtopics = secondItem.subtopics;
              }
            }
          });
          localQuestion.topics = localTopics;
          handleQuestionChange(localQuestion);
        }
        // You can handle further processing here if needed
      } catch (error) {
        console.error('Error parsing JSON file', error);
      }
    };
    reader.readAsText(file);
  }

  async function updateTopicIcons(topics) {
    let localTopics = [...topics];
    let iconRepresentationResp = await axiosInstance.post(apiPaths.topic_icons, {topics: localTopics}, status200);
    if (iconRepresentationResp && iconRepresentationResp.status && iconRepresentationResp.data && iconRepresentationResp.data.topics) {
      let topicsIcons = iconRepresentationResp.data.topics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
      return topicsIcons;
    }
    return topics;
  }

  const exportTopics = () => {
    const jsonData = JSON.stringify(question.topics, null, 2);
    const blob = new Blob([jsonData], {type: 'application/json'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${question.name}_topics.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
  return (
    <Grid container
          sx={{backgroundColor:'#FAFAFA'}}
          direction='column'
          id="container-topic-inference">
      <Grid container item direction='row' sx={{ml: '16px'}}>
        <Grid container item xs={'auto'} alignContent='center'>
          <Typography sx={{
            fontFamily: 'Raleway',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19px',
            letterSpacing: '0.0015em',
            color: '#616161'
          }}
          >
            {t('discover_topics_heading')}
          </Typography>
        </Grid>
        <Grid container item xs={true} justifyContent='flex-end' style={{marginRight:'20px'}}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(evt) => {
              setOpenImportExportModal(evt.currentTarget);
            }}
            sx={{
              width: '26px',
              height: '26px',
              borderRadius: '18px',
              border: '1px solid #E0E0E0',
            }}
          >
            <MoreVert sx={{
              color: '#616161',
              width: '16px',
              height: '16px'
            }}/>
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={openImportExportModal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(openImportExportModal)}
            onClose={() => setOpenImportExportModal(null)}
            sx={{
              '& .MuiMenu-paper': {
                borderRadius: '4px',
                width: '185px',
                padding: '0px 4px 4px 4px',
              },
              mt: '5px'
            }}
          >
            <MenuItem
              sx={{
                height: '50px',
                padding: '10px 12px 10px 12px',
                width: '150px',
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
                textTransform: 'none',
                textAlign: 'center',
              }}
              onClick={() => {
                exportTopics();
                setOpenImportExportModal(null);
              }}
            >
              <ListItemIcon>
                <Download sx={{
                  width: '24px',
                  height: '24px'
                }}/>
              </ListItemIcon>
              {t('export')}
            </MenuItem>
            <Divider/>
            <MenuItem
              sx={{
                height: '50px',
                width: '150px',
                padding: '10px 12px 10px 12px',
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
                textTransform: 'none',
                textAlign: 'center',
              }}
              onClick={() => {
                fileInputRef.current.click()
                setOpenImportExportModal(null);
              }}
            >
              <ListItemIcon>
                <UploadIcon
                  sx={{
                    width: '24px',
                    height: '24px'
                  }}/>
              </ListItemIcon>
              {t('import')}
            </MenuItem>
          </Menu>
          <input
            type="file"
            accept=".json"
            ref={fileInputRef}
            style={{display: 'none'}}
            onChange={handleImportTopics}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{mt: '24px'}}>
        <TopicExtractionControls question={question}
                                 parentQuestionCandidates={parentCandidates}
                                 segmentCandidates={segmentationCandidates}
                                 dataRegion={dataRegion}
                                 collection={collection}
                                 weightVariable={weight}
                                 handleQuestionChange={handleQuestionChange}/>
      </Grid>
      <Grid container item xs={12} direction='column'
            id="container-topic-autocomplete"
            sx={{
              width: "99%",
              flex: 1,
              mt:'19px',
              backgroundColor:'#F5F5F5',
              border:'1px solid #F5F5F5',
              borderRadius:'20px',
              padding:'24px 0px 14px 16px'
            }}>
        <Grid item container justifyContent="space-between" alignItems="center" sx={{backgroundColor:'#F5F5F5'}}>
          <Grid item>
            <Typography sx={{
              fontFamily: 'Raleway',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19px',
              letterSpacing: '0.0015em',
              color: '#616161'
            }}>{t('topics_list_heading')} ({question.topics?.length || 0})</Typography>
          </Grid>
        </Grid>
        <Grid container item direction='row'
              sx={{width: '100%',backgroundColor:'#F5F5F5'}} wrap={'nowrap'}>
          <Grid container item sx={{width: '77%'}}>
            <Topics
              question={question}
              handleQuestionChange={handleQuestionChange}
              dataRegion={dataRegion}
              collection={collection}
              weightVariable={weight}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{marginLeft: '15px'}}
          />
          <Grid container item sx={{width: '21%',minWidth:'215px', height: '100%'}}>
            <VerticalClassificationControls question={question}
                                            handleQuestionChange={handleQuestionChange}
                                            dataRegion={dataRegion}
                                            collection={collection}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
