import {
  Button, FormControlLabel,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import {alpha, darken} from "@mui/material/styles";
import Color from "color";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const MUIToggleGroup = styled(ToggleButtonGroup)(({theme}) => {
  return {
    height: '26px',
    borderRadius: '13px',
    border: `1px solid ${theme.palette.primary.main}`,
    alignContent:'center',
    alignItems:'center',
    backgroundColor: alpha(theme.palette.primary.main,0.2),
    padding:'4px',
    '& .MuiToggleButtonGroup-grouped:disabled': {
      border:'none'
    }
  }
});

export const MuiSelectedToggleButton= styled(ToggleButton)(({theme}) => {
  return {
    width:'45px',
    height:'18px',
    fontFamily:'Montserrat',
    fontSize:'10px',
    fontWeight:400,
    borderRadius:'9px',
    padding:'3px 7px',
    '.MuiToggleButton-root': {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      borderRadius:'9px',
    },
    '&.Mui-selected': {
      height:'18px',
      width:'42px',
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      borderRadius:'9px',
    },
    '&.MuiToggleButton-root:hover': {
      height:'18px',
      width:'42px',
      backgroundColor: theme.palette.primary.main,
    },
    '&.MuiToggleButton-root:disabled': {
      height:'18px',
      width:'42px',
      borderRadius:'9px',
      border:'none'
    }
  };
});

export const MuiToggleButton= styled(ToggleButton)(({theme}) => {
  const baseColor = Color(theme.palette.primary.main);
  const darkenedColor = baseColor.darken(0.1);
  const isDarkBackground = baseColor.isDark()
  const textColor = isDarkBackground ? "#ffffff" : darkenedColor.hex();
  return {
    backgroundColor: 'transparent',
    color: textColor,
    borderColor:'transparent',
    padding:'3px 7px',
    height:'18px',
    width:'42px',
    borderRadius:'9px',
    fontFamily:'Montserrat',
    fontSize:'10px',
    fontWeight:400,
    '&:hover': {
      backgroundColor: 'transparent',
      color: textColor,
      borderRadius:'9px',
      height:'18px',
      width:'42px',
    },
    '.MuiToggleButton-root': {
      borderRadius:'9px'
    }
  };
});

export const MuiOutlinedButton = styled(Button)(({theme}) => {
  const darkenedColor=darken(theme.palette.primary.main,0.2)

  return {
    padding: '10px 24px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '37px',
    width:'100%',
    border: `1px solid ${darkenedColor}`,
    borderRadius: '20px',
    color: darkenedColor,
    fontFamily: 'Montserrat',
    fontStyle: 'medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.0025em',
    textTransform:'uppercase',
  }
});

export const MuiFilledButton= styled(Button)(({theme}) => {
  return {
    padding: '4px 12px 4px 12px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '26px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontStyle: 'medium',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '0.0025em',
    textTransform:'uppercase',
    marginLeft:'8px',
  }
});


export const MuiInfoIconButton = styled(IconButton)(({theme}) => ({
  width: '16px',
  height: '16px',
  marginLeft: '4px',
}));

export const MuiInfoOutlinedIcon = styled(InfoOutlinedIcon)(({theme}) => ({
  width: '16px',
  height: '16px',
  color: '#9E9E9E',
}));

export const MuiRadioButton=styled(FormControlLabel)(({theme}) => {
  return {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.0025em',
      color: '#424242'
    },
    '& .MuiRadio-root.Mui-checked + .MuiFormControlLabel-label': {
      color: theme.palette.primary.main
    }
  }
})
