// External library imports
import {
  styled,
  Grid,
  Container,
  Toolbar,
  Tab,
  Typography,
} from '@mui/material';

export const MuiContainerNavBar = styled(Container)(({ theme }) => ({
  boxShadow: 'none',
  overflow: 'hidden',
  marginBottom: 40,
  maxWidth: 'calc(100% - 200px) !important',
  width:'calc(100% - 200px) !important',
  paddingLeft:'24px !important',
  paddingRight:'24px !important',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '1600px !important', // Sets a fixed maxWidth for large screens (optional)
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '24px !important', // Adjusts padding for smaller screens
    paddingRight: '24px !important',
  },
}));

export const MuiTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  paddingLeft: theme.spacing(1),
  marginLeft: theme.spacing(3),
  fontSize: 14,
  fontFamily:'Montserrat',
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
}));

export const MuiGridBox = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

export const MuiImage  = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  boxShadow: 'none',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: 20,
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize: 29,
  fontWeight:700,
  marginRight:theme.spacing(6),
  marginBottom:theme.spacing(2),
  fontStyle:'normal',
  fontFamily:'Raleway',
  color:'#212121',
  textAlign:'right'
}));

