import {
  Autocomplete, Button, Grid, lighten,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import * as React from "react";
import Color from "color";
import {alpha, darken} from '@mui/material/styles';

export const MuiAutocomplete = styled(
  (props) => (
    <Autocomplete
      {...props}
      multiple={false}
      variant="outlined"
      filterSelectedOptions
      renderInput={props.renderInput}
    />
  )
)(({theme}) => ({
  height: '56px',
  flex: 1,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#325D6C', // Custom border color
      borderWidth: '1px', // Custom border width
    },
    '&:hover fieldset': {
      borderColor: '#143440', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#254957', // Border color when focused
    },
  },
  '& .MuiInputBase-root': {
    height: '56px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
    color: '#424242',
    height: '20px',
    padding: '0px'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  }
}));

export const MuiTextField = styled(TextField)(({theme}) => ({
  height: '56px',
  maxHeight: '56px',
  borderRadius: '4px',
  '& .MuiInputBase-input': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.005em',
    fontWeight: 400,
    color: '#424242',
    minHeight: '56px',
    alignContent: 'center',
  },
  '& .MuiOutlinedInput-root': {
    maxHeight: '56px'
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    color: '#254957'
  },
}));

export const MUIToggleGroup = styled(ToggleButtonGroup)(({theme}) => {
  return {
    height: '32px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.primary.main}`,
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    padding: '4px',
  }
});

export const MuiSelectedToggleButton = styled(ToggleButton)(({theme}) => {
  return {
    height: '24px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    textTransform: 'none',
    gap: '10px',
    '.MuiToggleButton-root': {
      borderRadius: '12px',
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      borderRadius: '12px',
      padding: '4.5px 12px',
      // maxWidth:'170px'
    },
    '&.MuiToggleButton-root:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  };
});

export const MuiToggleButton = styled(ToggleButton)(({theme}) => {
  const baseColor = Color(theme.palette.primary.main);
  const darkenedColor = baseColor.darken(0.1);
  const isDarkBackground = baseColor.isDark()
  const textColor = isDarkBackground ? "#ffffff" : darkenedColor.hex();
  return {
    backgroundColor: 'transparent',
    color: textColor,
    borderColor: 'transparent',
    height: '24px',
    borderRadius: '12px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0.004em',
    textTransform: 'none',
    gap: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: textColor,
      borderRadius: '12px',
      height: '24px',
    },
    '.MuiToggleButton-root': {
      borderRadius: '12px'
    }
  };
});

export const MuiTooltipTypography = styled(Typography)(({theme}) => {
  return {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0.004em",
  };
});


export const MuiTopicAmountSelector = styled(Grid, {shouldForwardProp: (prop) => prop !== 'isSelected'})(({
                                                                                                            theme,
                                                                                                            isSelected
                                                                                                          }) => {
  return {
    width: '64px',
    height: '64px',
    background: isSelected ? lighten(theme.palette.primary.main, 0.875) : '#F5F5F5',
    borderRadius:'4px',
    cursor: 'pointer',
  }
})

export const MuiTopicAmountSelectorTypography = styled(Typography, {shouldForwardProp: (prop) => prop !== 'isSelected'})(({
                                                                                                                           theme,
                                                                                                                           isSelected
                                                                                                                         }) => {
  return {
    fontFamily: 'Raleway',
    fontWeight: 400,
    fontSize: '25px',
    lineHeight: '29px',
    letterSpacing: '0.0025em',
    color: isSelected ? theme.palette.primary.main : '#424242'
  }
})

export const MuiOutlinedButton=styled(Button)(({theme})=>({
  height: '37px',
  borderRadius: '18px',
  width:'116px',
  border:`1px solid ${darken(theme.palette.primary.main, 0.5)}`,
  color:darken(theme.palette.primary.main, 0.5),
  textTransform:'uppercase',
  fontFamily:'Montserrat',
  fontSize:'14px'
}));

export const MuiTextButton=styled(Button)(({theme})=>({
  height: '37px',
  borderRadius: '18px',
  width:'116px',
  color:darken(theme.palette.primary.main,0.5),
  textTransform:'uppercase',
  fontFamily:'Montserrat',
  fontSize:'14px'
}));
