import * as React from "react";
import {useEffect, useState} from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {
  MuiAutocomplete,
  MuiInfoIconButton,
  MuiInfoOutlinedIcon,
  MuiQuestionInfoTextField
} from "./styles/questionInfo";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import {inputStyle, labelInputStyle} from "../../styles/generalParameters";

const QuestionInfo = ({question, variables, handleQuestionChange}) => {

  const [answerVars, setAnswerVars] = useState([]);
  const [audioVars, setAudioVars] = useState([]);
  const [infoAnchor, setInfoAnchor] = useState(null);
  const [infoText, setInfoText] = useState('');

  const {t} = useTranslation();

  const initialize = () => {
    setAnswerVars(variables.filter(d => d.category === 'answer'));
    setAudioVars(variables.filter(d => d.category === 'audio'));
  }

  const showAnswerVarInfo = (event) => {
    setInfoText(t('answer_var_info'));
    setInfoAnchor(event.currentTarget);
  }

  const showAudioVarInfo = (event) => {
    setInfoText(t('audio_var_info'));
    setInfoAnchor(event.currentTarget);
  }

  const showShortNameInfo = (event) => {
    setInfoText(t('question_short_name_info'));
    setInfoAnchor(event.currentTarget);
  }

  const showLongNameInfo = (event) => {
    setInfoText(t('question_long_name_info'));
    setInfoAnchor(event.currentTarget);
  }

  useEffect(() => {
    initialize();
  }, [variables, question]);


  return (
    <Box sx={{width: '100%', margin: '24px'}}>
      <Grid container item direction='column' xs={12} rowGap='24px'
            sx={{width: '75%'}}>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              height: '19px',
              fontFamily: 'Raleway',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19px',
              letterSpacing: '0.0015em',
              color: '#616161'
            }}
          >
            {t('question_step_head')}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <FormControl fullWidth variant="outlined">
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <MuiAutocomplete
                value={answerVars.find((av) => av.propName === question.answerVar) || null}
                disableClearable
                onChange={(e, newValue) => {
                  if (newValue) {
                    let localQuestion = {...question};
                    localQuestion.answerVar = newValue.propName;
                    localQuestion.classified = false;
                    localQuestion.topics = [];
                    handleQuestionChange(localQuestion);
                  }
                }}
                renderInput={(params) => <TextField {...params}
                                                    sx={inputStyle}
                                                    label={question.answerVar ? t('answer_variable_placeholder') : t('question_text')}/>}
                options={answerVars}
                renderOption={(props, option) => {
                  return <MenuItem value={option.propName}
                                   key={option.propName} {...props}>{option.label}</MenuItem>
                }}
                getOptionLabel={(option) => option ? (option.label || option.propName) : ''}
                isOptionEqualToValue={(option, value) => option?.propName === value?.propName}
                variant="outlined"
                id="answer_variable_selection"
              />
              <MuiInfoIconButton onClick={showAnswerVarInfo}>
                <MuiInfoOutlinedIcon/>
              </MuiInfoIconButton>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <FormControl fullWidth variant="outlined">
              <MuiQuestionInfoTextField
                label={t('question_short_name_label')}
                id="short_name"
                defaultValue={question.name || ''}
                placeholder={t('question_short_name_description')}
                variant="outlined"
                onChange={(e) => {
                  question.name = e.target.value
                  handleQuestionChange(question)
                }}
                inputProps={{maxLength: 80}}
              />
            </FormControl>
            <MuiInfoIconButton onClick={showShortNameInfo}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <FormControl fullWidth variant="outlined">
              <MuiQuestionInfoTextField
                label={t('original_question_label')}
                placeholder={t('original_question_description')}
                defaultValue={question.longName || ''}
                id="long_name"
                variant="outlined"
                onChange={(e) => {
                  question.longName = e.target.value
                  handleQuestionChange(question)
                }}
                inputProps={{maxLength: 80}}
                InputLabelProps={{style: labelInputStyle}}
              />
            </FormControl>
            <MuiInfoIconButton onClick={showLongNameInfo}>
              <MuiInfoOutlinedIcon/>
            </MuiInfoIconButton>
          </Box>
        </Grid>
        <Grid container item xs={9}>
          <FormControl fullWidth variant="outlined">
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <MuiAutocomplete
                value={audioVars.find(av => av.propName === question.audioVar) || null}
                onChange={(e, newValue) => {
                  let localQuestion = {...question};
                  localQuestion.audioVar = newValue?.propName || '';
                  handleQuestionChange(localQuestion);
                }}
                renderInput={(params) => <TextField {...params}
                                                    InputLabelProps={{style: labelInputStyle}}
                                                    label={(question.audioVar ? t('audio_variable_placeholder') : t('audio_variable'))}/>}
                options={audioVars}
                renderOption={(props, option) => {
                  return <MenuItem value={option.propName}
                                   key={option.propName} {...props}>{option.label}</MenuItem>
                }}
                getOptionLabel={(option) => option ? (option.label || option.propName) : ''}
                isOptionEqualToValue={(option, value) => option?.propName === value?.propName}
                variant="outlined"
                id="answer_audio_variable_selection"
              />
              <MuiInfoIconButton onClick={showAudioVarInfo}>
                <MuiInfoOutlinedIcon/>
              </MuiInfoIconButton>
            </Box>
          </FormControl>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(infoAnchor)}
        anchorEl={infoAnchor}
        onClose={() => setInfoAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box style={{position: 'relative', width: '420px', padding: '10px'}}>
          <Grid container direction='row'>
            <Grid item sx={{padding: '5px', maxWidth: '374px'}}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#616161',
                fontStyle: 'normal',
                marginLeft: '5px'
              }} variant="caption">
                {infoText}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => setInfoAnchor(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  width: '15px',
                  height: '15px'
                }}
              >
                <CloseIcon sx={{width: '15px', height: '15px'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}

export default QuestionInfo;
