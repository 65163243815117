import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {MuiTextField} from "./styles/topicForm";
import {useCallback} from "react";


export const TopicForm = ({
                            topic,
                            index,
                            isRootTopic,
                            rootIsConditioned,
                            handleTopicChange
                          }) => {

  const getNumberSuffix = (value) => {
    switch (value) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const topicAttrChange=useCallback((attr,value)=>{
    handleTopicChange({...topic, [attr]:value,representativity:-1},index)
  },[topic,index,handleTopicChange]);

  return (
    <Grid container alignItems="center" alignContent={'center'} direction="row"
          columnGap={'8px'} wrap={'nowrap'} sx={{width: '100%'}}>
      {(isRootTopic && rootIsConditioned) ? (
        <Typography variant='h6'>
          {topic.topic}
        </Typography>
      ) : (
        <>
          <Grid item sx={{height: '50px', width: '40%'}}>
            <MuiTextField
              label={""}
              type="text"
              fullWidth
              onChange={(evt) => {
                topicAttrChange('topic',evt.target.value)
              }}
              // sx={{width: '250px'}}
              placeholder={`${index + 1}${getNumberSuffix(index + 1)} ${isRootTopic ? 'Topic' : 'Subtopic'}'s name`}
              value={topic.topic}
              variant="outlined"
              disabled={(isRootTopic && rootIsConditioned)}
            />
          </Grid>
          <Grid item xs={true} sx={{height: '50px'}}>
            <MuiTextField
              label={""}
              type="text"
              placeholder={`${index + 1}${getNumberSuffix(index + 1)} ${isRootTopic ? 'Topic' : 'Subtopic'}'s description`}
              onChange={(evt) => {
                topicAttrChange('description',evt.target.value)
              }}
              value={topic.description}
              sx={{width: '100%'}}
              variant="outlined"
              multiline
              rows={1}
              maxRows={2}
              disabled={(isRootTopic && rootIsConditioned)}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
